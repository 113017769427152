import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';

export const BringMeBack = (props: { className?: string; hash?: string }) => {
  const location = useLocation();
  const prevState = (location?.state as any)?.prevPath;
  return (
    <Link
      to={`/#${prevState ? prevState : ''}`}
      className={`text-red font-medium text-4xl no-underline inline-flex mt-16 ${
        props.className ?? props.className
      }`}
    >
      <span
        className="sigma-arrow down"
        style={{
          maxWidth: '80px',
          maxHeight: '80px',
          width: '80px',
          height: '80px',
        }}
      ></span>
      <span className="mt-8 uppercase text-highlight white">bring me back</span>
    </Link>
  );
};
