import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { BringMeBack } from '../components/shared/bring-me-back';

export const query = graphql`
  query {
    first: file(relativePath: { eq: "aboutus/01_04_juli.jpg" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    second: file(relativePath: { eq: "aboutus/02_14_juli.jpg" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    third: file(relativePath: { eq: "aboutus/03_18_juli.jpg" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fourth: file(relativePath: { eq: "aboutus/04_05_august.jpg" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fith: file(relativePath: { eq: "aboutus/05_21_september.jpg" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sixed: file(relativePath: { eq: "aboutus/06_04_juni.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    seventh: file(relativePath: { eq: "aboutus/07_17_juli.jpg" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    eight: file(relativePath: { eq: "wersindwir.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wueErleben: file(relativePath: { eq: "wueerleben.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    maamas: file(relativePath: { eq: "maamas_koop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jessi: file(relativePath: { eq: "jessi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sigmaMerch: file(relativePath: { eq: "sigma_merch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const AboutUsImage = (props: {
  image: any;
  innerCss?: string;
  width?: number;
}) => {
  return (
    <div
      className="relative"
      style={{ minWidth: `${props.width ? props.width : 300}px` }}
    >
      <div className={`xl:absolute ${props.innerCss}`}>
        <Img
          fixed={props.image}
          className="filter grayscale"
          alt="Sigma Wodka"
        />
      </div>
    </div>
  );
};

const aboutUs = (props: any) => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <SEO
        keywords={[
          `Sigma Bar Würzburg`,
          `Sigma Würzburg`,
          `Sigma Bar`,
          `Über die Sigma Bar`,
        ]}
        title="About"
      />
      <section className="mt-28">
        <div className="container">
          <div className="header">
            <h1>
              MEHR ÜBER UNS.
              <br />
              STARTED FROM THE BOTTOM NOW WE’RE HERE:
            </h1>
          </div>
          <div className="grid grid-cols-1">
            <div>
              <p>
                Normaler Samstag: Gemeinsamer Brunch, Pediküre, Wegbier und ein
                kurzer Satz auf dem Weg zum Abendessen: Schade, dass wir jetzt
                in keiner Bar Rage Cage spielen können. Mit diesem mindblowing
                Moment haben wir uns sofort auf den Bordstein gesetzt und über
                diese Idee philosophiert. Ab diesem Zeitpunkt gab es kein
                anderes Thema mehr. Nur noch die Frage am nächsten Morgen: „Wir
                machen das wirklich, oder?“ Und look at us: Exakt ein Jahr
                später stehen wir hinter der Theke, servieren euch Drinks und
                haben unseren Traum verwirklicht.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1"></div>

          <div className="grid grid-cols-1 xl:grid-cols-12 gap-1 xl:items-start mt-24">
            <div className="xl:col-span-6 xl:self-end pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col-reverse xl:space-x-5">
                <AboutUsImage
                  image={data.first.childImageSharp.fixed}
                ></AboutUsImage>
                <div className="inline-block">
                  <h3 className="font-calk text-red">04. Juli 2020</h3>
                  <p>
                    Die Idee ist geboren: Wir schmeißen eine Bar in Würzburg.
                  </p>
                  <p>
                    "Okay Idee ist da, aber bis wir eine Location haben, dauert
                    es ja sicherlich.“ 10 Min später: „Hab was,
                    Besichtigungstermin ist nächste Woche.“
                  </p>
                </div>
              </div>
            </div>

            <div className="xl:col-span-6 xl:col-start-9 pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col xl:space-x-5 justify-end">
                <div className="xl:h-80 inline-flex justify-center flex-col">
                  <h3 className="font-calk text-red">14. Juli 2020</h3>
                  <p>Okay Location ist nice, nehmen wir</p>
                </div>
                <AboutUsImage
                  image={data.second.childImageSharp.fixed}
                ></AboutUsImage>
              </div>
            </div>
            <div className="xl:col-span-9 xl:col-start-6 pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col-reverse xl:space-x-5 justify-start">
                <AboutUsImage
                  image={data.third.childImageSharp.fixed}
                ></AboutUsImage>

                <div className="xl:h-80 inline-flex justify-center flex-col">
                  <h3 className="font-calk text-red">18. Juli 2020</h3>
                  <p>
                    Pitch der Idee bei den Eigentümern. Fingers crossed, dass
                    sie auch Fanboys- und girls sind. Spoiler: Yes!
                  </p>
                </div>
              </div>
            </div>

            <div className="xl:col-span-6 xl:pt-28 pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col justify-start">
                <div>
                  <h3 className="font-calk text-red">24. Juli 2020</h3>
                  <p>
                    Mit unserer Soulmate Clara haben wir das perfekte Logo
                    entworfen. PS. Großes shout out an{' '}
                    <a
                      href="https://www.instagram.com/clarasdesign.de/?hl=de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Clara’s Design
                    </a>
                    , falls ihr mal was braucht. Pure Empfehlung.
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:col-span-9 xl:col-start-6 pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col xl:space-x-5 justify-end">
                <div className="xl:h-80 inline-flex flex-col justify-center">
                  <h3 className="font-calk text-red">05. August 2020</h3>
                  <p>
                    Wir vor 5 Jahren: Als ob wir unser BWL-Studium nochmal
                    brauchen würden. 15 Nachtschichten später sitzen wir mit
                    Businessplan und Bluse bei der Bank.
                  </p>
                </div>
                <AboutUsImage
                  image={data.fourth.childImageSharp.fixed}
                ></AboutUsImage>
              </div>
            </div>

            <div className="xl:col-span-6 pt-12 pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col space-x-5 justify-start">
                <div>
                  <h3 className="font-calk text-red">26. August 2020</h3>
                  <p>
                    „Leider können wir Sie bei Ihrem Vorhaben nicht
                    unterstützen.“ – Die Absage der ersten Bank hatte uns kurz
                    auf den Boden geholt. Auf die News erstmal ne Runde Rage
                    Cage.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="xl:col-span-4 pb-20 xl:pb-0">
              <h3 className="font-calk text-center">
                Waiting - Waiting - Waiting
              </h3>
            </div> */}
            <div className="xl:col-span-9 xl:col-start-6 xl:pt-32 pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col xl:space-x-5 justify-end">
                <div className="xl:h-80 inline-flex flex-col ">
                  <h3 className="font-calk text-red">27. August 2020</h3>
                  <p>
                    Nur ein Tag später: „Wir möchten gern Ihr
                    Finanzierungspartner der Bar SIGMA sein.“ Tja, man sollte
                    nie nur auf ein Pferd setzen.
                  </p>
                </div>
                <AboutUsImage
                  image={data.fith.childImageSharp.fixed}
                ></AboutUsImage>
              </div>
            </div>
            <div className="xl:col-span-6 xl:col-start-1 xl:pt-12 pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col space-x-5 justify-start">
                <div>
                  <h3 className="font-calk text-red">21. September 2020</h3>
                  <p>
                    Verträge unterschrieben, Schlüssel übergeben. Wir stehen
                    drin: In unserer Bar und vor einem Berg Arbeit.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="xl:col-span-2 xl:col-start-1 xl:pt-16 pb-20 xl:pb-0">
              <h3 className="font-calk ">
                WERTSTOFFHOF, STREICHEN, BAUMARKT, STERNLA, LACKIEREN, kleben,
                bohren, weinen, rage cage, strom abgestellt, HANDWERKER TERMIN
                BEKOMMEN…
              </h3>
            </div> */}
            <div className="xl:col-span-6"></div>
            <div className="xl:col-span-6 xl:col-start-9 xl:pt-12 pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col space-x-5 justify-start">
                <div>
                  <h3 className="font-calk text-red">09. Februrar 2021</h3>
                  <p>
                    Launch unserer Website. It’s official: Google kennt uns
                    jetzt.
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 xl:col-start-1 xl:pt-12 pb-20 xl:pb-0">
              <div className="flex flex-row justify-start">
                <div>
                  <h3 className="font-calk text-red">06. März 2021</h3>
                  <p>
                    Das Logo hängt an der Eingangstür. Der Bogen überstrichen.
                    Die letzten Spuren der Vorbesitzer sind endgültig weg.
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 hidden xl:block "></div>
            <div className="xl:col-span-12">
              <h3 className="font-calk xl:pl-8 pb-20 xl:pb-0">
                "EXCUSE ME SIR, WANN DÜRFEN WIR ERÖFFNEN?"
              </h3>
            </div>
            <div className="xl:col-span-9 xl:col-start-3 pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col xl:space-x-5 justify-end">
                <div className="inline-flex flex-col justify-center about-us-whatsapp">
                  <h3 className="font-calk text-red">04. Juni 2021</h3>
                  <p>
                    WhatsApp in der Gruppe: „Mädels, Söder hat gerade auf
                    Instagram gepostet, dass Innengastro ab nächsten Montag
                    aufmachen darf lol.“
                  </p>
                </div>
                <div className="xl:relative about-us-whatsapp-img">
                  <div className={`xl:absolute xl:-top-24`}>
                    <Img
                      fluid={data.sixed.childImageSharp.fluid}
                      className="filter grayscale about-us-whatsapp-img"
                      alt="Grasto darf aufmachen"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-9 xl:col-start-3 pb-20 xl:pb-0">
              <h3 className="font-calk">Finaler schlachtplan IN THE MAKING.</h3>
            </div>
            <div className="xl:col-span-6 xl:col-start-1 xl:pt-12 pb-20 xl:pb-0">
              <div className="flex xl:flex-row flex-col-reverse xl:space-x-5">
                <AboutUsImage
                  image={data.seventh.childImageSharp.fixed}
                ></AboutUsImage>
                <div>
                  <h3 className="font-calk text-red">17. Juli 2021</h3>
                  <p>Grand Opening. Purer Wahnsinn!</p>
                </div>
              </div>
            </div>

            <div className="xl:col-span-9 xl:col-start-3 xl:pt-96 pb-20 xl:pb-0">
              <div className="grid grid-cols-12">
                <div className="col-span-12 xl:col-span-6 ">
                  <h3 className="font-calk text-red">03. August 2021</h3>
                  <p>
                    Unser erstes{' '}
                    <a
                      href="https://www.wuerzburgerleben.de/2021/08/03/neuer-stern-kneipenhimmel-4-girls-eroeffnen-bar/"
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      Interview
                    </a>{' '}
                    bei wuerzburgerleben. Würzburger:innen kennen uns jetzt und
                    rennen uns die Bude ein. Mega!
                  </p>
                </div>
                <div className="col-span-12 xl:col-span-6 pt-5 xl:pt-0">
                  <Img
                    fluid={data.wueErleben.childImageSharp.fluid}
                    alt="Sigma Wuerzburg erleben"
                    className="filter grayscale"
                  />
                </div>
              </div>
            </div>
            <div className="xl:col-span-9 xl:col-start-1 xl:pt-32 pb-20 xl:pb-0">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 xl:col-span-6 pt-5 xl:pt-0">
                  <Img
                    fluid={data.maamas.childImageSharp.fluid}
                    alt="Maamas Kooperation"
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 ">
                  <h3 className="font-calk text-red">21. Oktober 2021</h3>
                  <p>
                    Erste Kooperation:{' '}
                    <a
                      href="https://www.maamas.de/"
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      maama’s
                    </a>{' '}
                    X SIGMA. Um 03:30 Uhr mit einem Bier in der Hand
                    kennengelernt und Friends-4-ever geworden. Das SIGMA Feeling
                    kann man sich jetzt nach Hause liefern lassen.
                    <br /> That shit cray.
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:col-span-9 xl:col-start-3 xl:pt-32 pb-20 xl:pb-0">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 xl:col-span-8 ">
                  <h3 className="font-calk text-red">04. November 2021</h3>
                  <p>
                    Unsere erste Mitarbeiterin Jessi. Wenn ihr denkt, ihr seht
                    nach drei Gin Tonic doppelt: Nope, sie sieht wirklich aus
                    wie Maggi. Willkommen in der besten Bar der Welt - vor und
                    hinter dem Tresen.
                  </p>
                </div>
                <div className="col-span-12 xl:col-span-4 pt-5 xl:pt-0">
                  <Img
                    fluid={data.jessi.childImageSharp.fluid}
                    alt="Mitarbeiter Jessi"
                  />
                </div>
              </div>
            </div>
            <div className="xl:col-span-9 xl:col-start-3 xl:pt-32 pb-20 xl:pb-0">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 xl:col-span-6 pt-5 xl:pt-0">
                  <Img
                    fluid={data.sigmaMerch.childImageSharp.fluid}
                    alt="Mitarbeiter Jessi"
                  />
                </div>
                <div className="col-span-12 xl:col-span-6 ">
                  <h3 className="font-calk text-red">22. November 2021</h3>
                  <p>
                    Wir launchen unsere erste Mode Kollektion: Homeboys wear
                    SIGMA. Homegirls own the game.{' '}
                    <a href="https://shop.bar-sigma.de" target="blank">
                      Kauf’s jetzt, thank us later.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:col-span-5 xl:col-start-7 xl:pt-24 pb-20 xl:pb-0">
              <div className="flex flex-col justify-end">
                <div>
                  {/* <h3 className="font-calk text-red">24. Juli 2021</h3>
                  <p>500 Follower auf Instagram. Thx!</p> */}
                  <p className="font-calk text-4xl pt-12">
                    Und das war nur der Start!
                  </p>
                </div>

                {/* <Img
                  fluid={data.eight.childImageSharp.fluid}
                  className="filter grayscale"
                  alt="Sigma Wodka"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container mt-9">
        <BringMeBack></BringMeBack>
      </div>
    </Layout>
  );
};
export default aboutUs;
